import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ModalService } from "../modal/modal.service";
import { EventStateChangeService } from "../../services/event.state.change.service";
import { EventService } from "../../services/event.service";
import { FlsService } from "../../services/fls.service";
import { ModalCancelRequestFormI } from "../modal/modals/modal.cancel.request.component";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25Util } from "../../util/s25-util";
import { Event } from "../../pojo/Event";
import { Task } from "../../pojo/Task";

@TypeManagerDecorator("s25-ng-cancel-request-button")
@Component({
    selector: "s25-ng-cancel-request-button",
    template: `
        @if (assigneeId && !hideButton) {
            <button (click)="openModal()" class="aw-button aw-button--outline">Request Cancellation</button>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25CancelRequestButtonComponent implements OnInit {
    @Input() itemId: number;

    assigneeId: number;
    hideButton: boolean = false;

    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        if (!(await this.canViewButton())) return;
        this.assigneeId = await EventStateChangeService.getTaskAssignee(this.itemId);
        this.cd.detectChanges();
    }

    async canViewButton() {
        const [currentState, fls] = await Promise.all([EventService.getEventState(this.itemId), FlsService.getFls()]);
        if (!(fls && fls.CREATE_TODO != "N" && currentState != Event.State.Ids.Cancelled)) return false;
        let [allowedStates, eventData] = await Promise.all([
            EventStateChangeService.getAllowedStates(this.itemId),
            EventService.getEventIncludeCached(this.itemId, ["workflow"]),
        ]);
        eventData = S25Util.array.forceArray(eventData);
        let todos = eventData[0]?.todo || [];
        const pendingRequests = todos.filter(
            (todo: { todo_subtype: number; cur_todo_state: Task.State }) =>
                todo.todo_subtype == Task.SubTypes.cancel && todo.cur_todo_state == Task.States.InProgress,
        );

        return !allowedStates.includes(Event.State.Ids.Cancelled) && pendingRequests?.length < 1;
    }

    openModal() {
        const modalBean: ModalCancelRequestFormI = {
            title: "Event Cancellation Request",
            itemId: this.itemId,
            assigneeId: this.assigneeId,
            onDone: () => {
                this.hideButton = true;
                this.cd.detectChanges();
            },
        };
        ModalService.modal("cancel-request", modalBean);
    }
}
